import Link from "next/link"
import { Flex, Image } from "@chakra-ui/react"

import { CloudImages } from "lib/cosmic/images"

export enum LogoVariant {
  Black,
  White,
}

interface LogoProps {
  variant?: LogoVariant
  context?: "navbar" | "footer"
}

const Logo = (
  { variant = LogoVariant.White, context = "navbar" }: LogoProps = {
    variant: LogoVariant.White,
    context: "navbar",
  },
) => {
  const height = context === "navbar" ? "44px" : "36px"

  return (
    <Link href="/">
      <Flex cursor="pointer">
        <Image src={variant === LogoVariant.White ? CloudImages.MARKETING_LOGO_WHITE : CloudImages.MARKETING_LOGO_BLACK} alt="right arrow" height={height} />
      </Flex>
    </Link>
  )
}

export default Logo
