import { useEffect } from "react"

import mixpanel from "mixpanel-browser"
import Head from "next/head"
import { Box } from "@chakra-ui/react"

import { getMixpanelProjectId } from "lib/clients/mixpanel"
import BingScript from "src/components/Website/BingScript"
import Footer from "src/components/Website/Footer"
import HubspotFormScript from "src/components/Website/HubspotFormScript"

import Navbar from "../components/Website/Navbar"

export enum NavbarTheme {
  Dark,
  Light,
}

interface WebsiteLayoutProps {
  title: string
  children: React.ReactNode
  navbarTheme?: NavbarTheme
}

const WebsiteLayout = ({ title, children, navbarTheme = NavbarTheme.Dark }: WebsiteLayoutProps) => {
  useEffect(() => {
    mixpanel.init(getMixpanelProjectId(), {
      debug: process.env.NODE_ENV === "development",
      track_pageview: true,
      persistence: "localStorage",
    })
  })

  return (
    <>
      <Head>
        <title>{title}</title>
        <BingScript />
        <HubspotFormScript />
      </Head>

      <Box fontFamily="var(--landing-page-font)">
        <Navbar theme={navbarTheme} />

        {children}

        <Footer />
      </Box>
    </>
  )
}

export default WebsiteLayout
