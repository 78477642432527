import mixpanel from "mixpanel-browser"
import * as Sentry from "@sentry/nextjs"

const MIXPANEL_PROJECT_IDS = {
  production: "f7d562757b602a20b020a028119a9219",
  staging: "5d26a0165589fde67db98060e6985a2b",
  development: "1622b7819d341b14bff482b9a9f958ce",
}

export const getMixpanelProjectId = () => {
  const env = process.env.NEXT_PUBLIC_APP_ENV

  // TODO: On local, NEXT_PUBLIC_APP_ENV is always defined but on staging
  // and production, it is not. This is a temporary fix.
  if (!env) {
    if (process.env.NEXTAUTH_URL?.includes("staging")) {
      return MIXPANEL_PROJECT_IDS["staging"]
    }

    return MIXPANEL_PROJECT_IDS["production"]
  }

  return MIXPANEL_PROJECT_IDS[env]
}

export const trackEvent = (eventName: string, eventProps?: object) => {
  try {
    if (eventProps) {
      mixpanel.track(eventName, eventProps)
    } else {
      mixpanel.track(eventName)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}
