import { Flex } from "@chakra-ui/react"

export const SocialIcon: React.ComponentType<{ link: string; mr?: string; children }> = ({ link, mr = "0", children, ...props }) => {
  return (
    <Flex
      w="32px"
      h="32px"
      align="center"
      justify="center"
      bg="#060B27"
      cursor="pointer"
      borderRadius="8px"
      border="1px solid var(--website-color-border-dark)"
      mr={mr}
      onClick={() => window.open(link, "_blank")}
      {...props}
    >
      {children}
    </Flex>
  )
}
